.minWidth-sidebar {
  width: 80px !important;
}

.site-layout-background {
  /* padding: 24px; */
  /* min-height: calc(100vh - 130px); */
  min-height: calc(100vh - 58px);
}

.child-custom-layout .btn-edit {
  background-color: #0494cc;
  padding: 5px 30px;
}

.new-app-header .nav-link {
  color: rgba(0, 0, 0, 0.5);
  border-right: 2px solid #d4d4d4;
  line-height: 5px;
}

.profile-img {
  border-radius: 50%;
}

/* .new-app-header .active {
  border-bottom: 3px solid rgb(6, 132, 222);
} */
.user-name {
  font-weight: 700;
}

.user-job {
  color: #0494cc;
}

.new-app-header .navbar-brand {
  font-size: 16px;
}

.new-app-header .nav-link {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.child-custom-layout {
  /* background-color: #e5e5e5; */
  background-color: #f1f5f9;
  margin-top: 57px;
}

.new-app-header {
  background-color: white !important;
  position: fixed;
  width: 95.3%;
  z-index: 2;
}

.new-app-header .title-resources {
  background-color: white !important;
  font-weight: 700;
}

.new-app-layout .updated-sidebar {
  /* background-color: #002248 !important; */
  border-top-right-radius: 25px;
  position: fixed;
  z-index: 1;
  height: 100%;
}

.new-generic-color {
  border: none;
  background-color: #2094cd;
  color: white;
}

.new-generic-color:hover {
  box-shadow: 0px 1px 7px 0px rgb(0 0 0 / 50%);
  background-color: #2094cd;
  color: white !important;
}

.generic-color-text {
  color: #2094cd !important;
}

/* .plan-your-day{
  border: 2px solid #2094cd !important;
  background-color: none !important;
} */
.generic-heading-level-3 {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21 !important;
  line-height: 18px;
  font-family: inter !important;
}

.Task-Description {
  height: 90px !important;
  line-height: 14px;
}

.generic-heading-level-2 {
  font-weight: 700;
  font-size: 17px !important;
}

.Welcome-txt {
  color: #5e81f4;
  font-size: 26px;
  font-weight: 600;
  font-family: 'Inter';
  line-height: 28px;
}

.plan-day {
  background-color: white !important;
  border: 2px solid #5e81f4 !important;
  color: #5e81f4 !important;
  font-weight: 500;
  font-family: 'Inter';
}

.specific-color {
  color: #8080806e;
}

.Have-nice-text {
  color: #666666 !important;
  font-size: 21px;
  font-family: 'Inter';
}

/* .sticky-height {
  height: 168px;
} */

/* .notes-scroll{
  height: 100% !important;
    max-height: 270px;
    min-height: 270px;
} */
.home-cards-height {
  margin-top: 28px;
}

.kanban-header-parent .main-dropdowns .dropdown button {
  background-color: white !important;
}

.free-Price {
  opacity: 0;
}

.main-tabs-content {
  padding: 4px !important;
  border: 1px solid lightgray !important;
  border-radius: 7px !important;
  margin-left: 100px !important;
  line-height: 13px;
}

.kanban-header-parent .main-dropdowns .dropdown button svg {
  color: #1c1d21 !important;
}

.attendence-height {
  height: calc(100vh - 227px) !important;
  overflow: auto;
}

.status-td-set {
  background-color: #ecfbe8;
  padding: 4px 12px;
  border-radius: 50px;
  color: #39c760;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 500;
}

.workLoad-content {
  height: 20rem !important;
}

.login-google {
  background-color: transparent;
  align-items: center;
  color: rgba(0, 0, 0, 0.54) !important;
  padding: 0px;
  display: block !important;
  /* border-radius: 2px; */
  font-size: 14px;
  box-shadow: none !important;
  font-weight: 400 !important;
  font-family: 'inter' !important;
}

.login-icon-btn .login-with-google {
  box-shadow: none !important;
  border: 1px solid lightslategray !important;
}

.login-google div {
  padding: 0px !important;
  margin: 0px !important;
}

.login-google div svg {
  display: none !important;
}

.check-box-generic {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.status-td-set-red {
  background-color: #ffd8d8 !important;
  padding: 4px 12px;
  border-radius: 50px;
  color: #e54545;
  font-size: 11px;
  font-family: 'Inter';
  font-weight: 500;
}

.status-td-set-reds {
  background-color: #e6e8f0 !important;
  padding: 4px 12px;
  border-radius: 50px;
  color: #666666;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 11px;
}

.List-btn {
  padding: 8px 15px;
  background-color: #dbe3ef;
  color: #5e81f4;
  border-radius: 3px;
  font-size: 14px;
  height: 38px;
  margin-top: 8px;
}

.last-text-clear {
  color: #b5b2b2 !important;
  font-size: 13px !important;
}

.grid-button {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  border: none;
  font-size: 13px;
  outline: none;
  height: 38px;
  transition: 0.3s;
  padding: 8px 15px !important;
  background-color: #4c89dc !important;
  margin-top: 8px;
}

.icon-color-list {
  color: #5e81f4;
}

.zoom-metting-logo {
  background-color: white;
  border-radius: 46px;
  width: 26px;
  height: 26px;
  position: relative;
  font-size: 18px;
}

.vedio-Body {
  background-color: black;
}

.vedioCall-end {
  background-color: #e54545 !important;
  border: none;
  font-size: 10px;
  padding: 4px 11px 2px 11px;
}

.signUp-conatainer {
  height: 100vh !important;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meeting_table-height {
  height: calc(100vh - 228px) !important;
}

.Home-Table thead th {
  border: none !important;
  font-size: 12px !important;
  color: black !important;
  text-transform: capitalize !important;
  background-color: #fafbff !important;
}

.card-bg-color {
  background-color: #ffe278 !important;
  border-radius: 17px;
  padding: 14px !important;
}

.card-paragraph {
  font-size: 12px !important;
  font-weight: 600;
}

.card-edit-background {
  font-size: 21px;
  color: white;
  background-color: black;
  padding: 4px;
  border-radius: 4px;
}

.date-sticky {
  color: gray;
}

.card-trash-background {
  font-size: 21px;
  color: rgb(212, 19, 19);
  background-color: rgb(243, 237, 237);
  padding: 4px;
  border-radius: 4px;
}

.Home-Table tbody tr td {
  font-size: 12px !important;
  color: #727274 !important;
  border: none;
}

.dashboard-modal {
  vertical-align: middle !important;
}

tbody tr .table-edit-icon {
  font-size: 14px !important;
}

.sticky-card {
  border-radius: 18px !important;
}

.stiky-Colors-open {
  height: 48px !important;
}

.stiky-color1 {
  background-color: #ffe66e;
  width: 14.28%;
}

.stiky-bg-modal1 {
  background-color: #ffe66e;
}

.stiky-color2 {
  background-color: #a1ef9b;
  width: 14.28%;
}

.stiky-bg-modal2 {
  background-color: #a1ef9b;
}

.stiky-color3 {
  background-color: #ffafdf;
  width: 14.28%;
}

.stiky-bg-modal3 {
  background-color: #ffafdf;
}

.stiky-color4 {
  background-color: #d7afff;
  width: 14.28%;
}

.stiky-bg-modal4 {
  background-color: #d7afff;
}

.stiky-color5 {
  background-color: #9edfff;
  width: 14.28%;
}

.stiky-bg-modal5 {
  background-color: #9edfff;
}

.stiky-color6 {
  background-color: #e0e0e0;
  width: 14.28%;
}

.stiky-bg-modal6 {
  background-color: #e0e0e0;
}

.stiky-color7 {
  background-color: #767676;
  width: 14.28%;
}

.stiky-bg-modal7 {
  background-color: #767676;
}

.generic-theme-color {
  color: #4c89dc !important;
  font-weight: 600;
}

.multi-hover:hover {
  background-color: #e0e0e0 !important;
}

.delete-modal {
  color: #e54545 !important;
}

.restore-modal {
  color: #5e81f4 !important;
}

.table-trash-icon {
  color: #e54545 !important;
  font-size: 14px !important;
}

tbody tr .table-trash-icon {
  color: #e54545 !important;
  font-size: 14px !important;
}

.Dribble-Website {
  color: #727274 !important;
}

.Development-text {
  color: #727274 !important;
}

.eFtULA {
  z-index: 99993 !important;
}

.to-do-background {
  background-color: #fafbff !important;
  border-radius: 13px;
}

#email-searchField #email-label {
  font-size: 19px !important;
}

.Schedule-Meeting {
  color: #727274;
}

.session-out-modal {
  z-index: 99999;
}

.add-scroll-card {
  height: 100% !important;
  overflow: scroll;
  overflow-x: hidden;
}

.link-underline {
  text-decoration: underline;
}

.Home-Table tbody tr:nth-of-type(even) {
  background-color: #fafbff !important;
}

.Home-Table tbody tr:nth-of-type(odd) {
  background-color: white !important;
}

.craete-card {
  align-self: center;
}

.new-setting-item a {
  padding: 10px !important;
  /* height: 43px; */
  display: flex !important;
  align-items: center !important;
}

.new-app-layout .updated-sidebar .sign-out-menu {
  box-shadow: 0px 0px 6px 0px #00000040;
  border: none;
  min-width: 19rem !important;
}

.new-app-header .navbar-toggler {
  margin-right: 30px;
}

.custom-drpdown .dropdown-menu {
  position: absolute !important;
  inset: 0px auto auto -77px !important;
  margin: 0px !important;
  transform: translate3d(0px, 40px, 0px) !important;
}

.Home-buttons {
  background-color: #5e81f4 !important;
}

.view-all-button {
  border: 1px solid #8080801f;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
}

.botton-border {
  border: 2px dotted #4c89dc !important;
}

.stiky-notes-modale {
  background-color: #fff2ab !important;
}

.stiky-notes-bg {
  background-color: #fff7d1 !important;
}

.new-app-layout .updated-sidebar .tenant-name {
  font-size: 11px;
  font-weight: 700;
}

.Take-Stiky-notes {
  height: 17em;
}

.checkbox-size [type='checkbox'] {
  font-size: 16px !important;
}

.calender-size-manage .fatsuA {
  width: 100% !important;
}

.form-group {
  margin-bottom: 0px !important;
}

.calender-size-manage .fatsuA .sc-dkrFOg {
  justify-content: space-around !important;
}

.iWvmpO {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0px, max-content));
  gap: 4px;
  justify-content: space-around !important;
}

.calender-size-manage .fatsuA .sc-dkrFOg .kVEIdA {
  width: 24px !important;
  height: 24px !important;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: 800 !important;
  font-family: inherit !important;
  color: #666 !important;
}

.hJAbuN {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.hImjaw {
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: inherit;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgb(6 6 6);
}

.calender-size-manage .fatsuA .sc-dkrFOg .cIFnvd {
  font-size: 13px !important;
  display: table-cell !important;
  font-weight: 700 !important;
}

.status-feild {
  min-width: 73px;
}

.meeting-card {
  background-color: #fafbff;
  border: 1px solid #d9d9d9;
  padding: 11px 8px 11px 9px;
  border-radius: 9px;
}

.custom-drpdown .dropdown-toggle::after {
  display: none !important;
}

.meetinf-date-card {
  padding: 13px;
  border-radius: 9px;
  align-self: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafbff;
  border: 1px solid #5f82f4;
  border-radius: 9px;
}

.login-icon-btn button {
  background-color: transparent;
}

.Upcomming-Meetings {
  color: #666666;
}

.btn-try-free {
  border-radius: 5px !important;
  background-image: linear-gradient(to right, #009eff, #00f38d) !important;
  color: #fff;
  border: none !important;
  box-shadow: 0px 0px 26px rgb(0 0 0 / 2%) !important;
  width: -webkit-fill-available;
  margin-right: 16px;
  margin-left: 15px;
}

.instagram-btn {
  background: #f09433;
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
  display: none;
}

.new-app-layout .updated-sidebar .btn-hi {
  border-radius: 50px !important;
  color: black;
  background-color: #79cef2;
  font-size: 13px !important;
  width: 35px;
  height: 35px;
  font-weight: 600;
  text-align: center;
  padding: 7px 8px;
  border: 2px solid white;
}

.settings-bg-color {
  background-color: #002248;
}

.user-profile-out {
  color: #60c3ff;
}

.sign-out-Profile {
  color: #676767 !important;
  font-weight: 600;
}

.feedback-system {
  background-color: #414fa0;
}

.feedback-system-icon-color {
  color: #414fa0;
}

.setting-system-icon-color {
  color: #002248;
}

.updated-layout-tabs .nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-right: 2px solid #d4d4d4 !important;
  line-height: 10px;
  color: #888888 !important;
}

.new-app-header .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1px 1rem !important;
  align-items: center;
  justify-content: space-between;
}

.updated-layout-tabs .nav-link.active {
  color: #1171d1 !important;
  border-bottom: 3px solid rgb(6, 132, 222);
}

.updated-layout-tabs .nav-tabs {
  border-bottom: 3px solid #adadad;
}

.new-app-layout .updated-sidebar ul li {
  font-size: 30px;
  cursor: pointer;
}

/* DrpDownHeadre CSS */
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.menu .button,
.menu a {
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 1rem 1.5rem 1rem 0.7rem;
}

.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}

.read-more-button svg,
.smooth {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -webkit-transition: all 0.7s;
}

.css-1i9kywd,
.default-link-subbar {
  position: relative;
}

.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}

.sub-menu {
  display: none;
}

.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.grouped-new-btns {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #adadad;
}

.new-sidebar-users {
  font-size: 12px !important;
}

.remaining_hr {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #adadad;
}

.updated-generic-btn {
  border-radius: 5px !important;
  background-image: linear-gradient(to right, #009eff, #00f38d) !important;
  color: #fff;
  border: none !important;
  /* font-family: inter; */
  font-weight: 400;
  font-size: 16px;
  padding: 10px 19px;
  box-shadow: 0px 0px 26px rgb(0 0 0 / 2%) !important;
}

.e-schedule .e-timeline-view .e-resource-collapse,
.e-schedule .e-timeline-month-view .e-resource-collapse {
  transform: rotate(270deg) !important;
  background-color: #fafbff !important;
  color: #5e81f4 !important;
  border-radius: 50px;
  border: 1px solid #5e81f4;
  font-size: 8px;
  position: relative;
  font-weight: 900;
  top: 6px;
  padding: 3px 5px;
  margin-right: 10px;
  margin-left: 7px !important;
}

.nerrow-dropdown .generic-dropdown {
  font-size: 12px !important;
  font-family: inter !important;
  font-weight: 500 !important;
  color: #666666 !important;
}

.e-schedule .e-timeline-view .e-resource-cells,
.e-schedule .e-timeline-month-view .e-resource-cells {
  height: 52px !important;
  padding-left: 5px !important;
  border: none !important;
}

.e-schedule .e-timeline-view .e-resource-cells:focus .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-cells:focus .e-resource-tree-icon {
  background-color: #fafbff !important;
  border-radius: 50%;
  color: #5e81f4 !important;
  transform: rotate(92deg);
}

.e-schedule .e-timeline-view .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-tree-icon::before {
  margin-left: 0px;
  border: 1px solid #5e81f4;
  border-radius: 20px;
  color: #5e81f4;
  margin-right: 10px;
  background-color: white;
  transform: rotate(269deg);
}

.e-schedule .e-timeline-view .e-resource-tree-icon,
.e-schedule .e-timeline-month-view .e-resource-tree-icon::afte {
  cursor: pointer;
  float: left;
  font-family: 'e-icons';
  font-size: 12px !important;
  transform: rotate(90deg) !important;
  padding: 5px 5px 3px 7px !important;
  font-size: 10px !important;
}

.e-resource-expand {
  transform: rotate(90deg) !important;
  padding: 4px 5px 3px 5px !important;
  font-size: 8px !important;
  margin-top: 5px;
  margin-left: 6px !important;
}

/* input[type="date"]:in-range::-webkit-datetime-edit-year-field, input[type="date"]:in-range::-webkit-datetime-edit-month-field, input[type="date"]:in-range::-webkit-datetime-edit-day-field, input[type="date"]:in-range::-webkit-datetime-edit-text { 	color: transparent; }
.new-background_workload{
  background-color: #FAFBFF;
  height: 52px;
  padding-left: 0px !important;
  border: none !important;
  display: none;
} */

input[type='date']::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}

.hide-availability {
  font-size: 12px;
}

.availibility-Na {
  font-size: 14px;
}

.button_today .today-date-btn {
  color: #666666 !important;
  background-color: #fafafa !important;
  border: 1px solid #d9d9d9 !important;
  font-size: 12px;
  font-weight: 500;
  font-family: inter;
}

.Unique-Features {
  height: 153px;
}

.footer-socialmedia {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-socialmedia:hover {
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
}

.footer-socialmedia:hover .icon {
  filter: invert(75%) sepia(85%) saturate(1605%) hue-rotate(199deg) brightness(332%) contrast(118%) !important;
}

.Social-facebook {
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
}

.dots-Slider {
  width: 7px;
  height: 7px;
  transition: width 1s, height 4s;
  background: #adadad;
  border-radius: 5px;
}

.dots-Slider:hover {
  background: #00cdc1;
  width: 24px;
}

.dots-Slider.active {
  background: #00cdc1;
  width: 24px;
}

.slick-prev:before {
  display: none !important;
}

.slick-next:before {
  display: none !important;
}

/* .slider-style{
  position: relative;
  bottom: 136px;
  right: 36%;
} */
.workflow-graph .video-height div {
  width: 640px !important;
  height: 91px !important;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
ul,
var,
video {
  border: 0;
  /* outline: 0; */
  vertical-align: initial;
  /* background: transparent; */
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
ul,
var,
video {
  border: 0;
  outline: 0;
  vertical-align: initial;
  background: transparent;
  margin: 0;
  padding: 0;
}

.mr0 {
  margin-right: 0 !important;
}

.clearfix {
  zoom: 1;
}

.pr0 {
  padding-right: 0 !important;
}

.css-aloouo {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
}

nav ul {
  list-style: none;
}

.nav-menu {
  cursor: pointer;
  padding: 16px 1px;
}

.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}

.read-more-button svg,
.smooth {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -webkit-transition: all 0.7s;
}

.css-1i9kywd,
.default-link-subbar {
  position: relative;
}

.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}

a,
button {
  text-decoration: none;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  -moz-transition: background 0.3s, color 0.3s, border-color 0.3s;
  -o-transition: background 0.3s, color 0.3s, border-color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s, border-color 0.3s;
}

.sub-menu {
  display: none;
}

.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

.absolute-center-v,
.btn-icon-box span.btn-icon-span,
.cross-common,
.cross-common svg,
.info-icon-box,
.info-icon-box:after,
.info-icon-box:before,
.input-icon,
.input-icon-right,
.slider-btn-center .frame+div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100%;
}

.r10 {
  right: 10px;
}

.down-arrow-menu {
  width: 11px;
  height: 8px;
  padding-top: 2px;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}

.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}

.down-arrow-menu:after {
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  right: 1px;
}

.css-1l5knwp:after {
  background: #3e3e3e;
}

.down-arrow-menu:after,
.down-arrow-menu:before {
  content: '';
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}

*,
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}

.default-link-subbar .default-link-subbar-line {
  bottom: -7px;
  left: 0;
  width: 0;
  height: 3px;
  display: block;
  position: inherit;
}

.position-relative-li {
  position: relative;
}

.sideNave-icon {
  color: #adadad;
}

.sideNave-icon:focus {
  color: #adadad;
  background-color: #002248;
}

.update-icon-backgrond {
  padding: 8px;
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  height: 40px;
  width: 40px;
  color: #ffff;
  border-radius: 3px;
}

.sub-menu {
  display: none;
}

.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

.bt {
  border-top: 1px solid #d4d4d4;
}

.css-13ab30w {
  background: #fff;
}

.container1,
.container-fluid1 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.container1 {
  width: 1000px !important;
  max-width: 100%;
}

.pb24 {
  padding-bottom: 24px !important;
}

.spaceBetween {
  justify-content: space-between;
}

.pt24 {
  padding-top: 24px !important;
}

.relative {
  position: relative !important;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.f16 {
  font-size: 16px;
  line-height: 20px;
}

.pb24 {
  padding-bottom: 24px !important;
}

.fw9 {
  font-weight: 900;
}

.css-e04fjx {
  padding: 0;
  margin: 0;
  -webkit-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px;
}

.css-e04fjx>li>a {
  font-size: 14px;
  color: #3e3e3e;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
  padding: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: break-spaces;
  text-align: left !important;
}

.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}

.css-1tmjqcs {
  float: right;
  width: 213px;
  height: 327px;
  overflow: hidden;
  position: relative;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}

.css-6ok6q2 {
  position: relative;
  height: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 153.52112676056336%;
}

.css-6ok6q1 {
  height: 327px;
  width: 213px;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}

.css-1te5b4g {
  position: relative;
  top: 0;
  left: -1px;
  min-width: calc(100% + 2px);
  max-width: 100%;
}

.default-link-subbar.hover-enabled:hover {
  background: #ffffff;
}

.css-1i9kywd:hover {
  color: #3e3e3e;
}

.menu .button,
.menu a {
  color: rgba(0, 0, 0, 0.5);
  border-right: 2px solid #d4d4d4;
  line-height: 1px;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 1px 30px;
}

.nav-menu:hover .sub-menu {
  display: block;
  list-style: none;
  position: absolute;
  z-index: 99;
  height: 200px;
  overflow: auto;
  width: 100%;
}

.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar {
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5 !important;
}

.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar-thumb {
  background-color: #0ae !important;
  min-height: 10px !important;
}

.r10 {
  right: 10px;
}

.down-arrow-menu {
  width: 11px;
  height: 8px;
  padding-top: 2px;
}

.absolute-center-v,
.btn-icon-box span.btn-icon-span,
.cross-common,
.cross-common svg,
.info-icon-box,
.info-icon-box:after,
.info-icon-box:before,
.input-icon,
.input-icon-right,
.slider-btn-center .frame+div {
  position: absolute;
  top: 41%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100%;
}

.down-arrow-menu:after,
.down-arrow-menu:before {
  content: '';
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}

.default-link-subbar.hover-enabled:hover .default-link-subbar-line {
  background: #368be6;
  width: 100%;
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}

.default-link-subbar.hover-enabled.active .default-link-subbar-line {
  background: #368be6;
  width: 100%;
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}

.down-arrow-menu:after {
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  right: 1px;
}

.css-1l5knwp:after {
  background: #3e3e3e;
}

.down-arrow-menu:after,
.down-arrow-menu:before {
  content: '';
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}

:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}

.down-arrow-menu:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  left: 2px;
}

.css-1l5knwp:before {
  background: #3e3e3e;
}

:after,
:before {
  box-sizing: border-box !important;
  outline: none;
  cursor: pointer;
}

.r10 {
  right: 10px;
}

.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 93%;
  position: absolute;
  z-index: 999;
  top: 58px;
  /* width: 9rem; */
  cursor: auto;
}

.div-rotation {
  width: max-content;
  background-color: #0073ea;
  transform: rotate(-90deg);
  font-size: 8px;
  padding: 5px 20px 5px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 42px;
}

.down-arrow-menu:after,
.down-arrow-menu:before {
  content: '';
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}

.down-arrow-menu:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  left: 2px;
}

.main-card-calender {
  height: calc(100vh - 105px) !important;
  overflow: auto;
}

.css-1l5knwp:before {
  background: #3e3e3e;
}

.circle-div {
  background-color: white;
  padding: 5px !important;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-top: 12px;
  margin-bottom: 5px;
  position: relative;
  left: 36px;
  font-size: 18px;
}

.my-purchased-text {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.font-size-10 {
  font-size: 12px !important;
  width: 117px !important;
  height: 95px !important;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.login-gmial-popup {
  width: 25rem !important;
  height: 32px !important;
  opacity: 0;
}

.login-gmial-popup:hover {
  width: 25rem !important;
  height: 32px !important;
  opacity: 0;
}

.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}

.arrw-icon-styling {
  position: absolute;
  left: 32px;
  color: #acaeb1;
  font-size: 40px;
  top: 0px;
}

.after-click-icn {
  width: 40px;
}

.hover-effect:hover {
  color: rgb(200, 194, 194);
}

.sidebar-item-clicked {
  position: absolute;
  margin-left: 73px;
  z-index: -9;
  /* max-height: 469px; */
  /* overflow-y: scroll; */
  top: -8px;
  left: 0;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: default;
}

.xeven-checkbox .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #4caf50;
  background-color: #4caf50;
}

.maxWidth-sidebar {
  width: 75px !important;
}

.app-logo-width {
  margin-top: 3px;
  width: 53px !important;
}

.new-app-layout .custom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  text-align: center;
  display: block;
}

.new-app-layout .content {
  margin-left: 75px;
  width: 96.1% !important;
}

@media only screen and (max-width: 1080px) {
  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  .collapse:not(.show) {
    display: none !important;
  }

  .css-13ab30w {
    background: #f1f1f1 !important;
  }

  .small-screen-padding {
    padding-left: 0px !important;
  }

  .small-screen-nargin-padding {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: fixed;
    display: block;
    flex-wrap: wrap;
    right: 9px;
    background-color: #ffffff;
    top: 74px;
    border-radius: 5px;
    height: auto;
    overflow-y: auto;
  }

  .css-qnpwi {
    box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
    min-height: 19%;
    left: -1px;
    top: auto !important;
    cursor: auto;
  }

  .nav-menu {
    cursor: pointer;
    padding: 12px 9px;
  }

  .menu a {
    border: none !important;
    padding: 2px 11px 1px 22px !important;
  }

  .r10 {
    left: 3px;
  }

  .new-app-header {
    background-color: white !important;
    padding: 12px 0px;
  }

  .new-app-headers {
    background-color: white !important;
    display: block !important;
  }

  .mini-header-custom-stylling {
    display: flex !important;
  }
}

.mini-header-custom-stylling {
  justify-content: end;
  margin: 2px 34px;
  background: #6e6bf4;
  position: fixed;
  right: -35px;
  top: -2px;
  padding: 9px;
}

@media only screen and (max-width: 1024px) {
  .main-first {
    width: 100%;
  }

  .folder_files {
    height: 164px !important;
  }
}

/* .side-img-gradient{
  background-image: url('../images/krakenimages.png');
} */
.Cloud-Optimized {
  font-size: 52px;
}

.tabs-New-color {
  color: rgb(173, 173, 173) !important;
  background-color: transparent !important;
  border: 1px solid rgb(173, 173, 173) !important;
}

element.style {
  color: rgb(173, 173, 173);
}

.alignment-center-set {
  display: flex;
  align-items: center;
}

.bg-default {
  background: #004660 !important;
  color: white !important;
}

.grediend {
  left: 0;
}

.terms_conditions {
  visibility: hidden;
}

/* .highcharts-bar-series .highcharts-point {
  fill: #00d179;
} */
.pm-dashboard-section .total_budget {
  color: #f0d15f;
}

.pm-dashboard-sectionv .remaining_budget {
  color: #035eca;
}

.pm-dashboard-section .cruntly_budget {
  color: #666666;
}

.pm-dashboard-section .stats_headding {
  color: #009eff;
}

.pm-dashboard-section .stats_kpi {
  color: #666666 !important;
}

.pm-dashboard-section .stat_fontSize {
  font-size: 14px;
}

.pm-dashboard-section .light_gray {
  color: #c5c2c2 !important;
  font-weight: 500 !important;
}

.pm-dashboard-section .green_bar {
  background-color: #00d179 !important;
}

@media only screen and (max-width: 1024px) {
  .Cloud-Optimized {
    font-size: 43px;
  }
}

.download-btn-size {
  padding: 7px 13px;
}

/* .updated-sidebar .nav-pills .show .nav-link .icons {
  filter: invert(75%) sepia(85%) saturate(1605%) hue-rotate(199deg) brightness(332%) contrast(118%) !important;
} */

.updated-sidebar ul .side-link {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updated-sidebar ul .side-link a {
  line-height: 1;
}

.updated-sidebar ul .side-link:hover,
.updated-sidebar ul .side-link.active {
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  color: #ffff;
  border-radius: 3px;
}

.updated-sidebar ul .side-link:hover .icons,
.updated-sidebar ul .side-link.active .icons {
  color: white;
}

.search-icon {
  color: #adadad;
  position: absolute;
  margin-left: 5px;
}

.align-input-field {
  padding-left: 25px !important;
  padding-right: 3rem !important;
}

.organization-screen .generic-theme-color {
  color: #666666 !important;
  font-weight: 600;
}

.organization-screen .bd-highlight {
  color: #666666;
  font-weight: 500;
}

.required_field {
  content: '*';
  color: #d0011b;
}

.bg-blu {
  background-color: #00aeea !important;
}

.bg-blu img {
  filter: invert(75%) sepia(85%) saturate(1605%) hue-rotate(199deg) brightness(332%) contrast(118%) !important;
}

.blueBorder {
  border: 1px solid #00aeea !important;
}

.iqASGh {
  padding: 24px 28px !important;
}

.vc-generic-btn-blue {
  background-color: #5e81f4;
  text-transform: capitalize;
  color: white;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.3s;
  padding: 9px 11px 7px 11px !important;
  font-size: 13px;
}

.notification-panel-header {
  padding: 15px 16px 23px 18px;
}

.Notification-images {
  height: 40px;
}

.messsage_request {
  color: #8c8c8c;
  font-size: 11px;
  font-family: inter;
  margin-top: 3px;
}

.all-notification {
  color: #8c8c8c;
  background-color: transparent !important;
  border: none !important;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 400;
  text-decoration: underline;
}

.massages_history {
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
}

.Mark-read {
  color: #009eff;
  font-family: 'Inter';
  font-size: 13px;
}

.Notifications-center {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 17px;
}

.side-link .dropdown-menu-right {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border: none !important;
  /* min-width: 19rem !important; */
}

/* .xeven-side-link {
  margin-top: 140px;
} */
/* .xeven-side-link .dropdown-menu-right {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border: none !important;
  min-width: 13rem !important;
  border-radius: 5px;
}
.xeven-side-link .nav-link:focus {
  background: none !important;
} */
.xeven-logo-size {
  width: 35px;
  height: 35px;
  border: 2px solid #000;
  border-radius: 20px;
}