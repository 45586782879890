/* sidebar css */

.sidebar {
  width: 220px;
  height: 100vh;
  background-color: #012b41;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 0;
  top: 56px;
  z-index: 1083;
  overflow: auto;
}

.sidebar hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.InfoName:hover {
  text-decoration: none;
  color: #fff;
}

.disappear {
  left: -100%;
}

.InfoContainer {
  display: flex;
  align-items: center;
  padding: 8px 0 0 10px;
}

.SidebarLink {
  position: relative;
  display: flex;
  color: white;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  text-decoration: none;
  padding: 8px 5px 8px 15px;
  transition: 0.5s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.SidebarLink img,
.SubnavLink img {
  height: 1rem;
  width: 1rem;
  object-fit: contain;
  margin-bottom: 3px;
}

.SidebarLink:nth-last-of-type(1) {
  /* color: red; */
  border-bottom: 0;
}

.SidebarLink:focus,
.SidebarLink:hover {
  text-decoration: none;
  color: white;
  background: #1a59702e;
  letter-spacing: 0.9px;
}

.AnchorContent {
  display: flex;
  align-items: center;
}

.AnchorContent svg {
  margin-bottom: 3px;
}

.SidebarLabel {
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 13px;
}

.SubnavLabel {
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 12px;
}

.SubnavIcon {
  position: absolute;
  right: 0;
  padding-right: 5px;
}

.SubnavIcon svg {
  font-size: 20px;
}

.selected-nav-item,
.selected-nav-item:hover {
  background: #fed284;
  color: #000;
  font-weight: 600;
}

.SubnavLink {
  position: relative;
  display: flex;
  color: white;
  justify-content: flex-start;
  font-size: 12px;
  align-items: center;
  list-style: none;
  text-decoration: none;
  padding: 8px 5px 8px 30px;
  cursor: pointer;
}

.SubnavLink:focus,
.SubnavLink:hover {
  background: #1a59702e;
  text-decoration: none;
  color: white;
}

.SubnavAnchorContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserImage {
  color: white;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: purple;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.UserImage.my-profile-img {
  height: 45px !important;
  width: 60px !important;
  font-size: 21px;
}

.initial-name {
  margin-bottom: 0px;
  color: #fff;
}

.Info {
  font-size: 12px;
  font-family: Gotham SSm, Helvetica, Arial;
  padding: 5px 5px 5px 10px;
}

.InfoName {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.userStatus {
  color: white;
  margin: auto;
  font-family: Gotham SSm, Helvetica, Arial;
  margin-left: 6px;
}

.sidebar::-webkit-scrollbar {
  width: 2px !important;
  background-color: #f5f5f5 !important;
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5 !important;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #0ae !important;
  min-height: 10px !important;
}

.quickLinkInfo {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff87;
  padding: 0px 15px;
  margin: 8px 0 8px 0;
}

.quickLinkContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #e9e9e9;
  padding: 8px 5px 8px 15px;
}

.quickLinkContent svg {
  margin-bottom: 3px;
}

.quickLinkContent p {
  margin: 0 0 0 10px;
  text-transform: uppercase;
  font-size: 13px;
}

.quickLinkContent:hover {
  background: #1a5970;
  text-decoration: none;
  color: white;
}

/* navbar css */

.navbar-wrapper {
  position: fixed;
  width: 100%;
  background-color: #2094cd;
  color: white;
  z-index: 3;
}

.logo-img {
  height: 35px;
  width: 35px;
}

.menu-btn {
  color: white;
  background: #379fd2;
  border: none;
  border-radius: 4px;
}

.navbar-xeven-btn {
  display: inline-block;
  position: relative;
  background: #00afd1;
  border-radius: 30px;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 53px 9px 23px;
  margin-left: 1rem;
}

.navbar-xeven-btn span {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 5px;
  top: 2px;
  border-radius: 50%;
  padding-top: 7px;
  padding-left: 3px;
  background: #00c4eb;
  transition: all 0.4s;
}

.navbar-xeven-btn span:hover {
  width: 173px;
  border-radius: 30px;
}

.userPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}

.userPanel:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .userPanel {
    justify-content: start;
    margin-left: 0px;
  }
}

/* wrapper css */
.wrapper-div {
  min-height: 100vh;
  padding: 72px 15px 15px 15px;
}

/******** NOTIFICATION PANEL STYLES ********/

.notification-panel-header {
  padding: 15px 16px 23px 18px;
}
.Mark-read {
  color: #009eff;
  font-family: "Inter";
  font-size: 13px;
}
.Notification-images {
  height: 40px;
}
.Notifications-center {
  font-family: "Inter";
  font-weight: 600;
  font-size: 17px;
}
.massages_history {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 600;
}
.messsage_request {
  color: #8c8c8c;
  font-size: 11px;
  font-family: inter;
  margin-top: 3px;
}
.notification-panel-body {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.all-notification {
  color: #8c8c8c;
  background-color: transparent !important;
  border: none !important;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  text-decoration: underline;
}
.notification-panel-body {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.notification-panel-body::-webkit-scrollbar {
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

.notification-panel-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5 !important;
}

.notification-panel-body::-webkit-scrollbar-thumb {
  background-color: #004660 !important;
  min-height: 10px !important;
}

.notification-panel-body .notif-hover:hover {
  opacity: 0.7;
}

.notification-panel-footer {
  box-shadow: 0px -3px 12px -8px #33333326;
  border-top: 1px solid #ddd;
}

.no-margin {
  margin: 0 !important;
}

.font-200 {
  font-size: 12px;
}

.vc-generic-btn {
  background-color: #004660;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.3s;
  padding: 5px 15px !important;
  font-size: 14px;
}

.no-padding {
  padding: 0 !important;
}

/* scrollbar */

body::-webkit-scrollbar {
  width: 8px !important;
}

/* / Track / */

body::-webkit-scrollbar-track {
  border-radius: 10px !important;
}

body {
  overflow-x: hidden;
  min-height: 100%;
  height: auto;
}

/* / Handle / */

body::-webkit-scrollbar-thumb {
  background: #00aaee !important;
  border-radius: 0px !important;
}
.content-header h1 {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}
.content-header .section-top-header h1 {
  line-height: 38px;
  font-size: 18px;
}

/* sidebar user organization trash client css */

.hiring-buttons .hiring-tabs {
  color: #adadad !important;
  background-color: white;
  border-radius: 5px;
}
.search-icon {
  color: #adadad;
  position: absolute;
  margin-left: 5px;
}
.align-input-field {
  padding-left: 25px !important;
  padding-right: 3rem !important;
}
.trash_select {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #666666 !important;
  background-color: #fafafa !important;
  background-clip: padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.modal-restore-icon-styling {
  height: 58px;
  background: rgba(164, 170, 243, 0.1);
  width: 58px;
  padding: 13px;
  border-radius: 50%;
}
.restore-modal {
  color: #5e81f4 !important;
}
.body-heading-style {
  font-size: 20px;
  color: #666666;
}
.vc-generic-btn-light {
  background-color: #d6dfe2;
  text-transform: capitalize;
  color: rgb(0, 0, 0);
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.3s;
  padding: 8px 15px !important;
  font-size: 14px;
}
.vc-generic-btn-blue,
.vc-generic-btn-danger {
  background-color: #5e81f4;
  text-transform: capitalize;
  color: white;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.3s;
  padding: 9px 11px 7px 11px !important;
  font-size: 13px;
}
.modal-delete-icon-styling {
  height: 58px;
  background: rgba(229, 69, 69, 0.1);
  width: 58px;
  padding: 13px;
  border-radius: 50%;
}
.delete-modal {
  color: #e54545 !important;
}
.vc-generic-btn-danger {
  background-color: #e54545;
  text-transform: capitalize;
}
.organization-screen .generic-theme-color {
  color: #666666 !important;
  font-weight: 600;
}
.generic-heading-level-2 {
  font-weight: 700;
  font-size: 17px !important;
}
.salary-labels {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #1c1d21 !important;
  font-family: "Inter";
  line-height: 16px;
}
.add_new_project .file_uploader {
  border: 2px dotted #adadad;
  border-radius: 6px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.add_new_project .file_uploader input {
  width: 100%;
  padding: 36px 0px;
  opacity: 0;
  cursor: pointer;
}
.add_new_project .uploaderTextSize {
  font-family: Inter;
}
.add_new_project .textColor {
  color: #5e81f4 !important;
}
.add_new_project .uploaderGrayText {
  color: #adadad;
}
.add_new_project .uploaderImg {
  width: 42px;
  height: 43px;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  border: none;
  border-radius: 6px !important;
}
.user_email_Icon-signIn {
  right: 10px;
  top: 5px;
  z-index: 3;
}
.hiring-buttons .active-hiring-tabs {
  background-color: #009eff !important;
  border: 1px solid #009eff !important;
  border-radius: 4px;
  color: white !important;
}
.pass_badge {
  background-color: #f1ffe8;
  color: #39c760;
  font-weight: 500;
  font-size: 14px;
}
.fail_badge {
  background-color: #fcecec;
  color: #fb4d4d;
  font-weight: 500;
  font-size: 14px;
}
