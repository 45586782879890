@media (max-width: 767px) {

  .table-responsive .dropdown-menu,
  .table-responsive .dropdown-toggle {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.cashCategoryTable tr th:last-child {
  width: 50px;
}

.swal2-styled {
  margin-top: 0 !important;
}

.billable-hours-profileimg {
  width: 80px !important;
  border-radius: 50% !important;
}

.tabdata table tbody td {
  border: none;
  font-size: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.quickLinkContent {
  margin-bottom: 100px !important;
}

.simple-table-custom tr td {
  border: none !important;
  vertical-align: middle !important;
  padding: 0.3rem 0.75rem !important;
}

.simple-table-custom tr th {
  border: none !important;
  /* border-bottom: 2px solid #ebedf3 !important; */
  vertical-align: middle !important;
}

.simple-table-custom tr th:last-of-type {
  border: none !important;
  /* border-bottom: 2px solid #ebedf3 !important; */
  vertical-align: middle !important;
  text-align: right !important;
}