/* Graphs  styling */
/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Nunito-Regular" !important;
  } */
.apexcharts-pie-area {
    stroke: transparent !important;
}
.apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
    flex-direction: column;
    bottom: 0;
    position: absolute !important;
    left: auto !important;
    top: 0px !important;
    right: 5px !important;
    margin: 2px 5px 10px 2px !important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left, .apexcharts-legend.apx-legend-position-top.apexcharts-align-left, .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
    justify-content: center !important;
}
.ceo-dashboard-tile .generic-card-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #009eff;
}
.ceo-dashboard-tile .generic-card-title .endofMonth {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #009eff;
}
.aroow-up-font-color {
    color: #00d179;
}
.buisness-dashboard-tile small {
    color: #666666;
}
.buisness-dashboard-tile h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #1c1d21;
}
.border-style-net-profit {
    width: 24px;
    height: 20px;
    background-color: #009eff;
    color: transparent;
    margin-right: 7px;
}
.gutter-b {
    margin-bottom: 24px;
}

.card-custom {
    border: none !important;
    border-radius: 6px;
}
.dashboard-card_primary {
    border: none;
}
.dashboard-card_primary .card-header {
    border-bottom: 2px solid #D9D9D9 !important;
    margin-left: 15pX;
    margin-right: 15pX;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    /* font-size: 24px; */
    background-color: #fff !important;
    line-height: 100%;
    color: #009eff;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

@media (max-width: 1550px) {
    .ceo-dashboard-tile .h3.generic-card-title {
        font-size: 15px !important;
    }
  }
  @media only screen and (max-width: 1398px) and (min-width: 1380px)  {
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 239px !important;
       }
  }
  @media only screen and (max-width: 1380px) and (min-width: 1360px)  {
    .finance-dashboard .ceo-dashboard-tile .previous-month{ 
      margin-bottom: 15px !important;
    }
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 269px !important;
       }
  }
  @media only screen and (max-width: 1360px) and (min-width: 1263px)  {
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 269px !important;
       }
  }
  @media only screen and (max-width: 1263px) and (min-width: 1206px)  {
    .finance-dashboard .ceo-dashboard-tile .previous-month-payable-medium{ 
     margin-top: 20px !important;
    }
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 289px !important;
       }
  }
  @media only screen and (max-width: 1206px) and (min-width: 1133px)  {
    .finance-dashboard .ceo-dashboard-tile .previous-month-payable{ 
     margin-top: 20px !important;
    }
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 286px !important;
       }
  }
  @media only screen and (max-width: 1435px) and (min-width: 1144px)  {
    .child-custom-layout{
        margin-top: 90px;
    }
  }
  @media only screen and (max-width: 1144px) and (min-width: 1081px)  {
    .child-custom-layout{
        margin-top: 145px;
    }
  }
  @media only screen and (max-width: 1133px) and (min-width: 1033px)  {
    .finance-dashboard .ceo-dashboard-tile .endofMonth{ 
     font-size: 9px !important;
    }
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 286px !important;
       }
  }
  @media only screen and (max-width: 1033px) and (min-width: 991px)  {
    .finance-dashboard .ceo-dashboard-tile .previous-month-responsive{ 
     margin-top: 20px !important;
    }
    .finance-dashboard .net-profit-grapgh .card-custom{ 
        height: 306px !important;
       }
  }
  @media only screen and (max-width: 1015px) and (min-width: 991px)  {
    .finance-dashboard .ceo-dashboard-tile .month-medium-screen{ 
     margin-top: 0px !important;
    }
  }

  @media (max-width: 550px) {
    .dashboard-card_primary .LastYear_Acitivty_Graph {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        overflow-x: auto;
    }
    
  }
